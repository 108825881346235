/*
 * @Author: your name
 * @Date: 2021-06-30 21:21:30
 * @LastEditTime: 2022-02-13 16:30:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/plugins/sopen.js
 */
import axios from 'axios';
import sha1 from 'sha1';
import CryptoJS from 'crypto-js';


var token = "LuoHuJiaoKeYuan"

var encKey = CryptoJS.enc.Utf8.parse('GftZqNEoBVdB2kzz');
var encIv = CryptoJS.enc.Utf8.parse('3zyJFPEzh5rUeUzz');
function eachEncrypt(vsData, encryptFields) {
	for (var key in vsData) {
		if (typeof vsData[key] == "object") {
			vsData[key] = eachEncrypt(vsData[key], encryptFields);
		} else {
			if (encryptFields.indexOf(key) > -1) {
				let deString = vsData[key];
				//deString = decodeURIComponent(deString);
				let deText1 = CryptoJS.AES.decrypt(deString, encKey, {
					mode: CryptoJS.mode.CBC,
					iv: encIv,
					padding: CryptoJS.pad.Pkcs7,
				});
				let deText2 = deText1.toString(CryptoJS.enc.Utf8);
				vsData[key] = deText2;
				if (key === "id" || key === "aid") {
					vsData[key] = parseInt(vsData[key]);
				}
			}
		}
	}
	return vsData;
}
function getUrls() {
	let host = window.location.host
	if (host.indexOf("127.0.0.1") > -1 || host.indexOf("localhost") > -1 || host.indexOf("sopen.cn") > -1 || host.indexOf("192.168.50.128") > -1) {
		return {
			get: "http://api.sopen.cn/learn-3.0/api_get_industry.php",
			post: "http://api.sopen.cn/learn-3.0/api_set_industry.php",
		}
	} else {
		return {
			get: "https://us.schoolopen.cn/learn-3.0/api_get_industry.php",
			post: "https://us.schoolopen.cn/learn-3.0/api_set_industry.php",
		}
	}
}

function getCent() {
	let host = window.location.host
	if (host.indexOf("127.0.0.1") > -1 || host.indexOf("localhost") > -1 || host.indexOf("sopen.cn") > -1 || host.indexOf("192.168.50.128") > -1) {
		return "http://api.sopen.cn/uscenter-2.0/api_get_industry-2.0.php"
	} else {
		return "https://us.schoolopen.cn/modeJson-uscenter2.0/api_get_industry-2.0.php"
	}
}


/*
补零
*/
function PrefixZero(num, n) {
	return (Array(n).join(0) + num).slice(-n);
}

function isWechat() {
	//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
	var ua = window.navigator.userAgent.toLowerCase();
	//通过正则表达式匹配ua中是否含有MicroMessenger字符串
	if (ua.match(/MicroMessenger/i) == 'micromessenger') {
		return true;
	} else {
		return false;
	}
}
/**
 * 
 * @returns 学年时间
 */
function getTerm() {
	var date = new Date();
	var Y = parseInt(date.getFullYear()); //获取完整的年份(4位)
	var m = parseInt(date.getMonth()); //获取当前月份(0-11,0代表1月)
	var d = parseInt(date.getDate()); //获取当前日(1-31)
	return (Y * 10000 + (m + 1) * 100 + d) - (Y * 10000 + 801) >= 0 ? Y : Y - 1;
}
function login(sdt) {
	return new Promise((resolve) => {
		let data = {
			token: "",
			module: "user",
			title: "login",
			data: sdt
		}
		let idate = new Date();
		let string = JSON.stringify(data);
		let time = Math.floor(idate.getTime() / 1000);
		let secret = sha1(string + time + token);
		let putData = {
			data: string,
			time: time,
			secret: secret
		}
		axios.post(getCent(), putData).then(function (response) {
			console.log(response)
			let data = response.data.user;
			if (data.errcode == "0") {
				resolve(data);
			} else {
				resolve(data);
			}
		}).catch(function (error) {
			console.log(error)
		})
	});
}

function getUsInfo() {
	let usinfo = getCookie('usCookie');
	if (usinfo !== 'null' && usinfo != null) {
		return JSON.parse(usinfo)
	} else {
		window.location.href = "/login"
		return false;
	}
}

function loginout() {
	localStorage.removeItem('uskey');
	localStorage.removeItem('usCookie');
	//setCookie("uskey", "", -1);
	//setCookie("usCookie", "", -1);
	window.location.href = "/login";
}


function getHelp(v) {
	let tokenValue = getCookie('usCookie');
	let data = {
		host: window.location.host,
		url: window.location.pathname,
		v: v,
	}
	let centerUrl = this.getCent();
	let pudt = {
		token: tokenValue,
		module: "help",
		title: "load",
		data: data,
	};
	let n = new Date();
	let string = JSON.stringify(pudt);
	let time = Math.floor(n.getTime() / 1000);
	let secret = sha1(string + time + token);
	let putDate = {
		data: string,
		time: time,
		secret: secret
	}
	return new Promise((resolve) => {
		axios.post(centerUrl, putDate).then(function (response) {
			resolve(response.data.help);
		}).catch(function (error) {
			console.log(error);
		})
	});
}

function loadAuthor(author) {
	var authorise = {};
	if (author.admin) {
		authorise = {
			admin: 1,
			master: 1,
			teacher: 1
		}
	} else if (author.master != undefined) {
		authorise = {
			master: 1,
			teacher: 1
		}
	} else if (author.teacher != undefined) {
		authorise = {
			teacher: 1
		}
	}
	if (author.student != undefined) {
		authorise["student"] = 1;
	} else if (author.adult != undefined) {
		authorise["adult"] = 1;
	}
	return authorise;
}

function fun_term(S) {
	var CT = "";
	if (S == 1) {
		CT = "托儿所";
	}
	if (S == 2) {
		CT = "幼儿园";
	}
	if (S == 3) {
		CT = "小学";
	}
	if (S == 4) {
		CT = "初中";
	}
	if (S == 5) {
		CT = "高中";
	}
	if (S == 6) {
		CT = "大学";
	}
	return CT;
}

function fun_classnum(N) {
	switch (N) {
		case 0:
			return "一";
		case 1:
			return "二";
		case 2:
			return "三";
		case 3:
			return "四";
		case 4:
			return "五";
		case 5:
			return "六";
		case 6:
			return "七";
		case 7:
			return "八";
		case 8:
			return "九";
		case 9:
			return "十";
		case 10:
			return "十一";
		case 11:
			return "十二";
	}
}

function GetAge(identityCard) {
	let len = (identityCard + "").length;
	let strBirthday = "";
	if (len == 18) {
		//处理18位的身份证号码从号码中得到生日和性别代码
		strBirthday =
			identityCard.substr(6, 4) +
			"/" +
			identityCard.substr(10, 2) +
			"/" +
			identityCard.substr(12, 2);
	}
	if (len == 15) {
		let birthdayValue = "";
		birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
		if (parseInt(birthdayValue) < 10) {
			strBirthday =
				"20" +
				identityCard.substr(6, 2) +
				"/" +
				identityCard.substr(8, 2) +
				"/" +
				identityCard.substr(10, 2);
		} else {
			strBirthday =
				"19" +
				identityCard.substr(6, 2) +
				"/" +
				identityCard.substr(8, 2) +
				"/" +
				identityCard.substr(10, 2);
		}
	}
	//时间字符串里，必须是“/”
	let birthDate = new Date(strBirthday);
	let nowDateTime = new Date();
	let age = nowDateTime.getFullYear() - birthDate.getFullYear();
	//再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
	if (
		nowDateTime.getMonth() < birthDate.getMonth() ||
		(nowDateTime.getMonth() == birthDate.getMonth() &&
			nowDateTime.getDate() < birthDate.getDate())
	) {
		age--;
	}
	if (isNaN(age)) {
		return '';
	} else {
		return age;
	}

}

function fun_className(S = '', Y = 0) {
	Y = Y < 1 ? getTerm() : Y;
	S = S.toString()
	var CT = parseInt(S.substring(4, 5));
	switch (CT) {
		case 1:
			CT = "托";
			break;
		case 2:
			CT = "幼";
			break;
		case 3:
			CT = "小";
			break;
		case 4:
			CT = "初";
			break;
		case 5:
			CT = "高";
			break;
		case 6:
			CT = "大";
			break;
	}
	var CY = "";
	if (Y - parseInt(S.substring(0, 4)) >= parseInt(S.substring(5, 6))) {
		CY = S.substring(0, 4) + "届";
	} else {
		CY = CT + this.fun_classnum(Y - parseInt(S.substring(0, 4)));
	}
	var CID = parseInt(S.substring(6, 8));
	if (CID > 0) {
		CID = "（" + PrefixZero(CID, 2) + "）班";
	} else {
		CID = "级";
		//CID = "";
	}
	return CY + CID;
}

function requestUscenterApi(data) {
	let tokenValue = getCookie('usCookie');
	if (tokenValue == false &&
		window.location.href.indexOf("/login") < 0 &&
		window.location.href.indexOf("/weChat") < 0 &&
		window.location.href.indexOf("/token") < 0 &&
		window.location.href.indexOf("/reg") < 0
	) {
		let surl = "/login?state=" + encodeURIComponent(window.location.pathname);
		if (window.location.search != "") {
			surl += encodeURIComponent("?" + window.location.search)
		}
		window.location.href = surl;
		return false;
	} else if (tokenValue != false) {
		data["token"] = tokenValue
	}
	let centerUrl = this.getCent();

	let n = new Date();
	let string = JSON.stringify(data);
	let time = Math.floor(n.getTime() / 1000);
	let secret = sha1(string + time + token);
	let putData = {
		data: string,
		time: time,
		secret: secret
	}
	//console.log(centerUrl, putData)
	return new Promise((resolve) => {
		axios.post(centerUrl, putData).then(function (response) {
			resolve(response);
		}).catch(function (error) {
			console.log(error);
		})
	});
}

function requestApi(data) {
	let urls = getUrls();
	let learnUrl = data["put"] && data["put"] == "post" ? urls.post : urls.get;
	if (!(data["module"] == "user_info" && data["title"] == "setcookie")) {
		let uskey = getCookie("uskey")
		//console.log(uskey)
		if (uskey == false) {
			//window.location.href = "/login";
			this.$router.push({
				path: "/login",
				query: {
					state: "/"
				}
			});
			return false;
		}
		data["token"] = uskey
	}
	let n = new Date();
	let string = JSON.stringify(data);
	let time = Math.floor(n.getTime() / 1000);
	let secret = sha1(string + time + token);
	let putDate = {
		data: string,
		time: time,
		secret: secret
	}
	//console.log(learnUrl,putDate)
	//data["server"] = this.$route.path.indexOf("/teach")>-1?"school":"area";
	return new Promise((resolve) => {
		axios.post(learnUrl, putDate).then(function (response) {
			let sdata = response.data
			let encryptFields = sdata.encryptFields;
			let encryptedData = sdata.encryptedData;
			let ndDate = eachEncrypt(encryptedData, encryptFields);
			//let edata = aesDecrypt(sdata)
			resolve(ndDate);
		}).catch(function (error) {
			console.log(error);
		})
	});
}
function setCookie(key, cookie, outtime = 0) {
	if (outtime == 0) outtime = 1800;
	let exp1 = new Date();
	let time = Math.floor(exp1.getTime() / 1000);
	let secret = sha1(cookie + time + token);
	let putData = {
		data: cookie,
		time: time,
		secret: secret
	}
	putData = JSON.stringify(putData)
	//let skc = key + "=" + putData + "; expires=" + exp1.toGMTString() + "; path=/; domain=" + (window.location.hostname == "localhost" ? "localhost" : "schoolopen.cn");
	//let skc = key + "=" + putData + "; path=/; domain=" + (window.location.hostname == "localhost" ? "localhost" : "schoolopen.cn");
	//document.cookie = skc
	//console.log(key, skc)
	localStorage.setItem(key, putData);
}

function getCookie(key = "") {
	if (key == "") {
		key = "usCookie";
	}
	let cookie = localStorage.getItem(key);
	//let cookie = fun_cookie(key);
	if (cookie == null) return false;
	let putData = JSON.parse(cookie);
	if (putData != null &&
		putData["data"] && putData["time"] && putData["secret"]) {
		let n = new Date();
		let time = Math.floor(n.getTime() / 1000);
		if (window.location.hostname != "localhost" && time - putData["time"] > 3600) {
			localStorage.removeItem(key);
			return false;
		}
		let secret = sha1(putData["data"] + putData["time"] + token);
		if (secret != putData["secret"]) {
			return false;
		} else {
			if (time - putData["time"] > 60) setCookie(key, putData["data"])
			return putData["data"];
		}
	} else {
		if (window.location.href.indexOf("/login") < 0 && window.location.href.indexOf("/weChat") < 0 && window.location.href.indexOf("/reg") < 0) {
			localStorage.removeItem(key);
			window.location.href = "/login"
		}
		return false;
	}
}

export default {
	getUrls,
	getCent,
	getTerm,
	PrefixZero,
	isWechat,
	//checkToken,
	login,
	loginout,
	getUsInfo,
	requestUscenterApi,
	getHelp,
	fun_term,
	fun_classnum,
	fun_className,
	GetAge,
	loadAuthor,
	requestApi,
	setCookie,
	getCookie
}