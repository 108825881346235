/*
 * @Author: your name
 * @Date: 2021-07-27 16:03:10
 * @LastEditTime: 2021-11-28 10:30:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const router = new VueRouter({
  //mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'homePage',
      //component: Home,
      component: () => import('../views/home/index.vue'),
      meta: {
        title: '社区首页'
      }
    },
    {
      path: '/weChat/authorize',
      name: 'authorize',
      meta: {
        title: 'authorize'
      },
      component: () => import('../views/weChat/authorize.vue')
    },
    {
      path: '/weChat/oauth2',
      name: 'oauth2',
      meta: {
        title: 'oauth2'
      },
      component: () => import('../views/weChat/oauth2.vue')
    },
    {
      path: '/weChat/authorize-login',
      name: 'wechatCodeLogin',
      meta: {
        title: 'oauth2'
      },
      component: () => import('../views/weChat/authorize-login.vue')
    },
    {
      path: '/weChat/oauth2-login',
      name: 'wechatCodeLogin2',
      meta: {
        title: 'oauth2'
      },
      component: () => import('../views/weChat/oauth2-login.vue')
    },
    {
      path: '/weChat/usinfo',
      name: 'usinfo',
      meta: {
        title: 'usinfo'
      },
      component: () => import('../views/weChat/usinfo.vue')
    }, {
      // 注册页
      path: '/token',
      name: 'register',
      meta: {
        keepAlive: true
      },
      component: () => import('@/views/token')
    },
    {
      path: '/reg',
      name: 'reg',
      meta: {
        keepAlive: true
      },
      component: () => import('@/views/Reg.vue'),
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        keepAlive: true
      },
      component: () => import('@/views/login.vue'),
    },
    {
      path: '/user',
      name: 'login-index',
      component: () => import('../views/login/index.vue')

    }, {
      path: '/user/change',
      name: 'login-change',
      component: () => import('../views/login/change.vue')
    }, {
      path: '/user/usinfo',
      name: 'login-usinfo',
      component: () => import('../views/login/usinfo.vue')
    }, {
      path: '/user/phone',
      name: 'login-phone',
      component: () => import('../views/login/phone.vue')

    }, {
      path: '/user/passwd',
      name: 'login-passwd',
      component: () => import('../views/login/passwd.vue')
    }, {
      path: '/user/parent',
      //name: 'parent',
      name: 'login-parent',
      //component: parent
      component: () => import('../views/login/parent.vue')
    }
  ]
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router