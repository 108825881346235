/*
 * @Author: your name
 * @Date: 2021-07-27 16:01:32
 * @LastEditTime: 2021-12-09 10:46:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import sopen from './plugins/sopen'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.min.css'
import './plugins/sopen.css'

// 导入全部的规则
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import {
  digits,
  min,
  max,
  email,
  required,
  between,
  integer,
  confirmed
} from "vee-validate/dist/rules";


// 2.导入要使用的语言
import zhCN from "vee-validate/dist/locale/zh_CN.json";

import './registerServiceWorker'

localize({
  // 对象成员简易赋值
  zhCN,
});
// 4. 使用具体的语言
localize("zhCN");
extend("digits", digits);
extend("min", min);
extend("max", max);
extend("email", email);
extend("integer", integer);
extend("confirmed", confirmed);
//extend("required", required);
extend("required", {
  ...required,
  //message: "{_field_}是必选项目",
  message: "必选项目",
});

extend("between", between);
extend("password", {
  validate(value) {
    var reg =
      /*
      /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}/;
      */
      /(?=.*[a-z])(?=.*[0-9]).{8,16}/;
    /*
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,]).{8,16}$/;
    */
    let flag = reg.test(value);
    return flag == true
  },
  // 字符串占位符, 占位符内容由ValidationProvider组件name参数提供
  message: '{_field_}密码过于简单'
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.prototype.$sopen = sopen
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')


document.title = "学校OA"