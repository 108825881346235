<!--
 * @Author: your name
 * @Date: 2021-07-27 16:01:32
 * @LastEditTime: 2021-12-05 09:55:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /vue-project/src/App.vue
-->
<template>
  <v-app>
    <v-container class="grey lighten-4 pa-0" style="max-width: 100%">
      <router-view class="grey lighten-4" />
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  created() {
    /*
    if (sessionStorage.getItem("uskey") && sessionStorage.getItem("usCookie")) {
      localStorage.setItem("uskey", sessionStorage.getItem("uskey"));
      localStorage.setItem("usCookie", sessionStorage.getItem("usCookie"));
      sessionStorage.removeItem("uskey");
      sessionStorage.removeItem("usCookie");
    }
    */
  },
  mounted() {
    /*
    window.onbeforeunload = function () {
      sessionStorage.setItem("uskey", localStorage.getItem("uskey"));
      sessionStorage.setItem("usCookie", localStorage.getItem("usCookie"));
      localStorage.removeItem("uskey");
      localStorage.removeItem("usCookie");
    };
    */
  },
  watch: {},
};
</script>
